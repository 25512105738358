
const config = {
  "account": {
    "contact": "andrew@bullishstudio.com",
    "id": "bullish-studio",
    "imageUrl": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/bullish-studio/assets/img/profile.png"
  },
  "assets": {
    "img": {
      "brand": {
        "backgrounds": {
          "profile": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/bullish-studio/assets/img/brand/backgrounds/profile.v2.png"
        },
        "logo": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/bullish-studio/assets/img/brand/logo.v2.png",
        "logo-white": "https://storage.googleapis.com/avant-garde-cdn/axon/accounts/bullish-studio/assets/img/brand/logo-white.png"
      }
    }
  },
  "footer": {
    "company": {
      "link": "https://bullishstudio.com",
      "name": "Bullish, LLC"
    }
  },
  "theme": {
    "palette": {
      "color-1": "#F1EDE9",
      "color-2": "#161C2A",
      "color-3": "#E1F963",
      "color-4": "#73757A",
      "color-5": "#FFFFFF"
    }
  }
}

export default config;
