/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React imports
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

// Modules
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import accounting from 'accounting-js';

// nodejs library to set properties for components
import PropTypes from "prop-types";

// node.js library that concatenates classes (strings)
import classnames from "classnames";

// javascipt plugin for creating charts
import { Chart } from "chart.js";

// react plugin used to create charts
import { Line } from 'react-chartjs-2';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Spinner
} from "reactstrap";

import {
  chartOptions,
  parseOptions
} from "variables/charts.js";
// Get last 7 days
let dates = [];
let values = [];
for (let i = 0; i < 7; i++) {
    // Setup default
    dates.push(moment().subtract(i, 'days').format('YYYY-MM-DD'));
    values.push(0);
}

// Persona break down
let personaDataDetails = {
  matches: {},
  lifetimeValue: {},
  revenueLift: {}
};

// Chart data placeholders
let chartData = {
  matches: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Matched Customers",
            data: values,
          }
        ]
      };
    }
  },
  lifetimeValue: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Lifetime Value",
            data: values,
          }
        ]
      };
    }
  },
  revenueLift: {
    default: (canvas) => {
      return {
        labels: dates,
        datasets: [
          {
            label: "Projected Revenue Lift",
            data: values,
          }
        ]
      };
    }
  }
};

// Query: Customer Activity
async function getCustomerActivity(account, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Get all current customers
  let customerActivityQuery = {
    "date_from": startDate,
    "date_to": endDate,
    "events": [
        {
            "id": "Customer Activity",
            "math": "dau",
            "name": "Customer Activity",
            "type": "events",
            "order": 0,
            "properties": [
              {
                  "key": "type",
                  "type": "event",
                  "value": [
                      "Persona Assignment"
                  ],
                  "operator": "exact"
              },
              {
                  "key": "properties.persona.version = '0.0.6'",
                  "type": "hogql",
                  "value": null
              },
              {
                  "key": "clientId",
                  "type": "event",
                  "value": [
                      `${account.config.defaultClient}`
                  ],
                  "operator": "exact"
              }
            ],
            "custom_name": "Persona Assignments"
        }
    ],
    "interval": "week",
    "breakdown": "properties.persona.name",
    "entity_type": "events",
    "breakdown_type": "hogql"
  };

  // Execute query
  const customerActivityResponse = await axios.post(eventsApiUrl, customerActivityQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Split results
  let matches = [];
  let lifetimeValue = [];
  let revenueLift = [];
  for (var i = 0; i < customerActivityResponse.data.result.length; i++) {
    // Grab value
    let customerPersonaStat = customerActivityResponse.data.result[i];

    // Check: Matches
    if(customerPersonaStat.action.custom_name == "Persona Assignments") {
      matches.push({
        label: (customerPersonaStat.breakdown_value.indexOf("null") > 0) ? "(None)" : customerPersonaStat.breakdown_value,
        data: customerPersonaStat.data,
        days: customerPersonaStat.days,
        daysAbrev: customerPersonaStat.labels
      });
    }
  }


  // Return values
  return {
    personas: customerActivityResponse.data.result,
    matches: matches,
    lifetimeValue: lifetimeValue,
    revenueLift: revenueLift
  };
}

// Query: Customer (by Persona)
async function getCustomers(account, personaName, startDate, endDate) {
  // Set events API
  const eventsApiUrl = `https://app.posthog.com/api/projects/${account.config.events.project}/insights/trend/`;

  // Get all current customers
  let customerActivityQuery = {
    "date_from": startDate,
    "date_to": endDate,
    "events": [
      {
        "id": "Customer Activity",
        "math": "dau",
        "name": "Customer Activity",
        "type": "events",
        "order": 0,
        // "math_hogql": "max(properties.lifetime_value)",
        "properties": [
            {
                "key": "clientId",
                "type": "event",
                "value": [
                    "getmainelobster"
                ],
                "operator": "exact"
            },
            {
                "key": "type",
                "type": "event",
                "value": [
                    "Persona Assignment"
                ],
                "operator": "exact"
            }
        ]
      }
    ],
    "interval": "week",
    "breakdown_type": "hogql",
    "breakdown": "person.id",
    "entity_type": "events"
  };

  // Execute query
  const customerActivityResponse = await axios.post(eventsApiUrl, customerActivityQuery, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${account.config.events.api}`
    }
  });

  // Return values
  return {
    customers: customerActivityResponse.data.result
  };
}

function PersonasChart({ profile, account }) {

  // Default states
  const [ loading, setLoading ] = React.useState(true);
  const [ activeNav, setActiveNav ] = React.useState(2);
  const [ personaData, setPersonaData ] = React.useState([]);
  const [ activeChart, setActiveChart ] = React.useState("matches");
  const [ activeChartData, setActiveChartData ] = React.useState("default");

  // Toggle 
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    //setActiveChartData(activeChartData === "30-day" ? "90-day" : "30-day");
  };

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // // Get history
  // const history = useHistory();

  // Set KPI values
  const [ totalMatches, setTotalMatches ] = React.useState(0);
  const [ optimizedLifetimeValue, setOptimizedLifetimeValue ] = React.useState(0.0);
  const [ projectedRevenueGrowth, setProjectedRevenueGrowth ] = React.useState(0.0);

  // Set macro values
  const [ totalMatchessAll, setTotalMatchesAll ] = React.useState(0);
  const [ optimizedLifetimeValueAll, setOptimizedLifetimeValueAll ] = React.useState(0.0);
  const [ projectedRevenueGrowthAll, setProjectedRevenueGrowthAll ] = React.useState(0.0);

  // Load persona data
  React.useEffect(() => {
    if (account.initialized) {
      // Customer Activity
      (async () => {
        // Get customer details
        const customerActivity = await getCustomerActivity(account, "2024-03-29", "2024-05-31T23:59:59");
        console.log("Persona Customer Activity: ", customerActivity);

        /*
         *  [1] Update Chart: Persona Matches
         */
        let matchesData = [];
        let matchesLabels = [];
        let totalMatches = 0;
        for(var i = 0; i < customerActivity.matches.length; i ++) {
          // Iterate over all data elements
          for(var j = 0; j < customerActivity.matches[i].data.length; j++) {
            // Set defaults
            if(i > 0) {
              // Increment
              matchesData[j] += customerActivity.matches[i].data[j];
            } else {
              // Set defaults
              matchesData = customerActivity.matches[i].data;
              matchesLabels = customerActivity.matches[i].days;
            }
          }

          // Update persona stats
          if(customerActivity.matches[i].label !== "(None)") {
            // Update data details
            personaDataDetails['matches'][customerActivity.matches[i].label] = {
              name: customerActivity.matches[i].label,
              total: _.sum(customerActivity.matches[i].data)
            };

            // Increment total
            totalMatches += _.sum(customerActivity.matches[i].data);
          }

          // Update chart
          let personaMatchData = customerActivity.matches[i].data;
          chartData.matches[customerActivity.matches[i].label] = (canvas) => {
            return {
              labels: matchesLabels,
              datasets: [{
                label: "Matches",
                data: personaMatchData
              }]
            };
          };
        }

        // Update chart
        chartData.matches['all'] = (canvas) => {
          return {
            labels: matchesLabels,
            datasets: [{
              label: "Matches",
              data: matchesData
            }]
          };
        };

        // Update stats
        setTotalMatches(_.sum(matchesData));
        setTotalMatchesAll(_.sum(matchesData));

        // Update persona data
        for(const personaName in personaDataDetails['matches']) {
          // Update total
          personaDataDetails['matches'][personaName]['strength'] = 100.0 * personaDataDetails['matches'][personaName]['total'] / totalMatches;
        }

        // // Set customer data
        // if(customerActivity.personas && customerActivity.personas.length > 0) {
        //   // Track total matches
        //   let totalMatchedCustomers = 0;

        //   // Create dataset (7day)
        //   let dataset7Days = []
        //   for(var i = 0; i < customerActivity.personas.length; i ++) {
        //     // Add data
        //     console.log(customerActivity.personas[i]);
        //     dataset7Days.push({
        //       label: customerActivity.personas[i].label,
        //       data: customerActivity.personas[i].data.slice(Math.max(customerActivity.personas[i].data.length - 7, 0))
        //     });

        //     // Update customer count
        //     totalMatchedCustomers += _.sum(customerActivity.personas[i].data);
        //   }

        //   // Set chart data (7-day)
        //   chartData.customerActivity['7-day'] = (canvas) => {
        //     return {
        //       labels: customerActivity.personas[0].days.slice(Math.max(customerActivity.personas[0].days.length - 7, 0)),
        //       datasets: dataset7Days
        //     };
        //   };

        //   // Create dataset (30-day)
        //   let dataset30Days = []
        //   for(var i = 0; i < customerActivity.personas.length; i ++) {
        //     // Add data
        //     dataset30Days.push({
        //       label: customerActivity.personas[i].label,
        //       data: customerActivity.personas[i].data
        //     });
        //   }

        //   // Set chart data (30-day)
        //   chartData.customerActivity['30-day'] = (canvas) => {
        //     return {
        //       labels: customerActivity.personas[0].days,
        //       datasets: dataset30Days
        //     };
        //   };

        //   // Set default flag
        //   setActiveChartData("30-day");
        //   setTotalMatches(totalMatchedCustomers);
        // }

        // Set chart
        console.log("Chart Data: ", chartData);
        setActiveChartData('all');

        // Set persona data
        let updatedPersonaData = [];
        console.log("Persona Data Details: ", personaDataDetails[activeChart]);
        for(const personaName in personaDataDetails[activeChart]) {
          // Update total
          updatedPersonaData.push(personaDataDetails[activeChart][personaName])
        }
        setPersonaData(updatedPersonaData);

        // Update loading
        setLoading(false);

        /*
         *  [4] Populate Initial Customer Personas
         */

        // Populate customer details
        const customers = await getCustomers(account, null, "2024-03-29", "2024-05-31T23:59:59");
        console.log("Customer Personas (List): ", customers);
      })();
    }
  }, [ account.initialized ]);

  // Handle chart click
  const selectChart = (chartName) => {
    // Update chart
    setActiveChart(chartName);

    // // Set persona data
    // let updatedPersonaData = [];
    // for(const personaName in personaDataDetails[chartName]) {
    //   // Update total
    //   updatedPersonaData.push(personaDataDetails[chartName][personaName])
    // }
    // setPersonaData(updatedPersonaData);
    // console.log(" - Updated Persona Data: ", updatedPersonaData, chartName, personaDataDetails);

    // // Update KPI's
    // setTotalConversions((activeChartData == 'all') ? totalConversionsAll : personaDataDetails['conversions'][activeChartData]['total']);
    // setCostPerAcquisition((activeChartData == 'all') ? costPerAcquisitionAll : personaDataDetails['costPerAcquisition'][activeChartData]['total']);
    // setRevenueLift((activeChartData == 'all') ? revenueLiftAll : personaDataDetails['revenueLift'][activeChartData]['total']);
  }

  // Handle chart click
  const selectPersona = (selectedPersonaName) => {
    // Update chart
    setActiveChartData((selectedPersonaName == activeChartData) ? 'all' : selectedPersonaName);

    // Set persona data
    let updatedPersonaData = [];
    for(const personaName in personaDataDetails[activeChart]) {
      // Update total
      updatedPersonaData.push(personaDataDetails[activeChart][personaName])
    }
    setPersonaData(updatedPersonaData);
    console.log(" - Updated Persona Data: ", updatedPersonaData, activeChart, personaDataDetails);

    // Update KPI's
    setTotalMatches((selectedPersonaName == activeChartData) ? totalMatchessAll : personaDataDetails['matches'][selectedPersonaName]['total']);
    // setCostPerAcquisition((selectedPersonaName == activeChartData) ? costPerAcquisitionAll : personaDataDetails['costPerAcquisition'][selectedPersonaName]['total']);
    // setRevenueLift((selectedPersonaName == activeChartData) ? revenueLiftAll : personaDataDetails['revenueLift'][selectedPersonaName]['total']);
  }

  return (
    <Row>
      <Col md="12">
        <Card className="bg-primary">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <div className="col">
                <h6 className="text-light text-uppercase ls-1 mb-1">
                  Personas
                </h6>
                <h5 className="h3 text-white mb-0">Active Customers</h5>
              </div>
              <div className="col">
                <Nav className="justify-content-end" pills>
                  <NavItem className="mr-2 mr-md-0">
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: activeNav === 1,
                      })}
                      href="#pablo"
                      onClick={(e) => toggleNavs(e, 2)}
                    >
                      <span className="d-none d-md-block">30 Days</span>
                      <span className="d-md-none">M</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("py-2 px-3", {
                        active: activeNav === 2,
                      })}
                      data-toggle="tab"
                      href="#pablo"
                      onClick={(e) => toggleNavs(e, 1)}
                    >
                      <span className="d-none d-md-block">90 Days</span>
                      <span className="d-md-none">W</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'matches' ? `bg-white` : 'bg-primary'}`}
                  onClick={() => selectChart('matches')}
                  style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'matches' ? `text-primary` : 'text-white'}`}
                        >
                          New Matched Customers
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${activeChart == 'matches' ? `` : 'text-white'}`}>{ totalMatches }</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'optimizedLifetimeValue' ? `bg-white` : 'bg-primary'}`}
                  // onClick={() => selectChart('optimizedLifetimeValue')}
                  // style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'optimizedLifetimeValue' ? `text-primary` : 'text-white'}`}
                        >
                          Optimized Lifetime Value
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>{accounting.formatMoney(optimizedLifetimeValue)}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12" xl="4">
                <Card
                  className={`card-stats text-white ${activeChart == 'projectedRevenueGrowth' ? `bg-white` : 'bg-primary'}`}
                  // onClick={() => selectChart('projectedRevenueGrowth')}
                  // style={{ cursor: 'pointer' }}
                >
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className={`text-uppercase text-muted mb-0 ${activeChart == 'projectedRevenueGrowth' ? `text-primary` : 'text-white'}`}
                        >
                          Projected Revenue Growth
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0`}>{accounting.formatMoney(projectedRevenueGrowth)}</span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{
              height: 300
            }}>
              <Col xl="3">
                { loading ? 
                  <Row style={{ marginTop: 100, flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Spinner color="white" /> 
                  </Row>
                  :
                  <ListGroup className="list my--3 bg-primary" flush>
                  { personaData.map((persona, index) => (
                    <ListGroupItem
                      className={`px-0 ${persona.name == activeChartData ? 'bg-white rounded px-2' : 'bg-primary border-0'}`}
                      onClick={() => selectPersona(persona.name)}
                      style={{ cursor: 'pointer' }}
                      key={index}
                    >
                      <Row className="align-items-center">
                        <div className="col">
                          <h5 className={`${persona.name == activeChartData ? '' : 'text-white'}`}>{persona.name}</h5>
                          <Progress
                            className="progress-xs mb-0"
                            color={persona.name == activeChartData ? 'primary' : 'info'}
                            max="100"
                            value={persona.strength}
                          />
                        </div>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
                }
              </Col>
              <Col xl="9">
                <div style={{
                  height: '100%'
                }}>
                  <Line
                      data={chartData[activeChart][activeChartData]}
                      options={chartOptions.options}
                      id="customer-activity"
                      className="chart-canvas"
                    />         
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

// Connect to store
const ConnectedPersonasChart = connect(state => ({
  profile: state.profile,
  account: state.account
}))(PersonasChart);

export default ConnectedPersonasChart;
