/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';

// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

// Account config
import config from "config";

// Import firebase
import { database, firestore } from "./../firebase";

// Auth actions
import {
  INITIALIZE_ACCOUNT,
  UPDATE_ACCOUNT,
  SET_PERSONAS
} from 'store/actions';

function Admin({ profile, account, knowledge, dispatch }) {
  // States
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const mainContentRef = React.useRef(null);

  // [INIT] Load account data
  useEffect(() => {
    // [1] 
    if(account && !account.initialized) {
      // Set client list
      console.log("Loading Account: ", config);

      // Access account data
      console.log(" - Getting: " + `accounts/${config.account.id}`)
      database.ref(`accounts/${config.account.id}`)
        .on("value", function(snapshot) {
          // Parse account
          const accountConfig = snapshot.val();

          // Check for existence
          if(accountConfig) {
            console.log(" - Account Config: ", accountConfig);

            // Select active client
            let activeClientId = accountConfig.defaultClient ? accountConfig.defaultClient : "default";

            // Check for selection override
            if(profile && profile.selected) {
              activeClientId = profile.selected.client;
            }

            // Extract client data
            let allClients = [];
            Object.keys(accountConfig.configs).map(clientId => {
              if(accountConfig.configs[clientId].active) {
                // Add to roster
                allClients.push(accountConfig.configs[clientId]);
              }
            });

            // Initialize account
            dispatch({
              type: INITIALIZE_ACCOUNT,
              payload: {
                config: {
                  accountId: accountConfig.accountId,
                  atlasUrl: accountConfig.atlasUrl,
                  name: accountConfig.name,
                  version: accountConfig.version,
                  defaultClient: activeClientId,
                  events: accountConfig.events
                },
                clients: allClients
              }
            });
          }
        });
    }

    // Load Personas
    if (account.initialized && profile.initialized && knowledge.entities && knowledge.entities.length === 0) {
      console.log("Loading Personas: ", account, profile);

      // Select active cli  ent
      let activeClientId = account.config.defaultClient ? account.config.defaultClient : "default";
      let activeClient = null;
      let availableClients = [];

      // Check for selection override
      for(var i = 0; i < account.clients.length; i++) {
        // Check for active
        if(profile.user.selected) {
          // Check for active client
          if(account.clients[i].id == profile.user.selected.client) {
            // Set active client and ID
            activeClient = account.clients[i];
            activeClientId = account.clients[i].id;
          } else {
            // Add to roster
            availableClients.push(account.clients[i]);
          }
        } else {
          // Check for active client
          if(account.clients[i].id == activeClientId) {
            // Set active client and ID
            activeClient = account.clients[i];
            activeClientId = account.clients[i].id;
          } else {
            // Add to roster
            availableClients.push(account.clients[i]);
          }
        }
      }

      // Query firestore
      firestore.collection("personas_v0.9")
        .where("account", "==", account.config.accountId)
        .where("client", "==", activeClientId)
        .onSnapshot((querySnapshot) => {
          // Iterate and append to list
          let personas = [];
          querySnapshot.forEach((doc) => { personas.push(doc.data().data); });

          // Update personas
          dispatch({
            type: SET_PERSONAS,
            payload: personas
          });

          // Update account
          dispatch({
            type: UPDATE_ACCOUNT,
            payload: {
              active: activeClient,
              available: availableClients
            }
          });
        }, (error) => {
          console.log(error);
        });
    }
  }, [profile, account]);

  // Scroll to top
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  // Brand text
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };

  // Nav theme
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "dark"
      : "light";
  };

  return (
    <>
      <Sidebar
        routes={routes}
        toggleSidenav={toggleSidenav}
        sidenavOpen={sidenavOpen}
        logo={{
          innerLink: "/",
          imgSrc: config.assets.img.brand.logo,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          brandText={getBrandText(location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/home" />
        </Switch>
        <AdminFooter />
      </div>
      {sidenavOpen ? (
        <div className="backdrop d-xl-none" onClick={toggleSidenav} />
      ) : null}
    </>
  );
}

// Connect to store
const ConnectedAdmin = connect(state => ({
  profile: state.profile,
  account: state.account,
  knowledge: state.knowledge
}))(Admin);

export default ConnectedAdmin;
